<template>
  <div>
    <div class="properties__org-name">
      <p class="properties__org-header">Наименование организации</p>
      <div class="properties__line"></div>
      <p class="properties__name">Министерство науки и высшего образования Российской Федерации</p>
    </div>
    <div class="properties__docs">
      <p class="properties__docs-header">Документы организации</p>
      <div class="properties__line"></div>
      <!-- v-for компонент документа-->
      <div class="properties__document-div">
        <p class="properties__document-name">Выписка из ЕГРЮЛ</p>
        <p class="properties__document-file">some-file-name.ext</p>
        <img src="@/assets/icons/print.svg" alt="print" class="properties__document-print">
        <img src="@/assets/icons/folder.svg" alt="file" class="properties__document-add">
        <img src="@/assets/icons/delete-round.svg" alt="delete" class="properties__document-delete">
      </div>
      <div class="properties__document-div">
        <p class="properties__document-name">Выписка из ЕГРЮЛ</p>
        <p class="properties__document-none">Не загружено</p>
        <img src="@/assets/icons/print.svg" alt="print" class="properties__document-print">
        <img src="@/assets/icons/folder.svg" alt="file" class="properties__document-add">
        <img src="@/assets/icons/delete-round.svg" alt="delete" class="properties__document-delete">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Properties"
}
</script>

<style scoped>

</style>